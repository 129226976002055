/* eslint-disable prettier/prettier */
// eslint-disable-next-line no-unused-vars
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

export interface ConfirmationDialogRawProps {
  handleClose: () => void;
}

function TermsAndConditions(props: ConfirmationDialogRawProps) {
  const { handleClose } = props;

  return (
    <div>
      <DialogContentText>
        Thank you for visiting our Portal. Before you go further you must read, understand, and agree to comply with the terms of use (Portal Terms). These Portal Terms apply when you access the Portal, and the most recent version (replacing older versions) will apply each time you log in. They are a binding legal agreement between you and us. If you do not agree to be bound by these Portal Terms, then do not access or use the Portal.
        <br/>
        <br/>
        In these Portal Terms:
        <br/>
        <br/>
        “Affiliate(s)” means a subsidiary or a holding company of the Bank or any subsidiary of the holding company and all of its subsidiaries;
        <br/>
        <br/>
        “Bank/we/us” means Stanbic Bank Zambia Limited and its Affiliates;
        <br/>
        <br/>
        “Intellectual Property” means works of copyright, trade marks (statutory and common law), patentable inventions, patents, protectable design subject matter, designs, and domain names including applications, registrations, and unregistered forms of the foregoing, all other intellectual property rights (registered or unregistered), and including the right to apply for all of the foregoing;
        <br/>
        <br/>
        “Know-How” means Trade Secrets, concepts, ideas, data, specifications, models, schematics, prototypes, methodologies, or formulas and rights in any information granted by any law from time to time under the law of any country unless such information is part of the general knowledge or literature which is generally available for public use from other lawful sources;
        <br/>
        <br/>
        “Portal Ideas” means any ideas, concepts, or business solutions (including any Intellectual Property and Know-How therein or associated therewith) supplied by you on the Portal or jointly created by you and Bank including any enhancements and modifications thereto;
        <br/>
        <br/>
        “Trade Secrets” means information including but not limited to a formula, pattern, compilation, program, device, method, technique, or process that: a) is generally unknown to the public; b) derives economic value (actual or potential) for the possessor; and c) has been subject to reasonable steps by the person lawfully in control of the information to keep it secret; and
        <br/>
        <br/>
        “You/your” means all persons accessing this Portal.
        <br/>
        <br/>
        Intellectual Property
        <br/>
        <br/>
        By accessing the Portal you agree that:
        <br/>
        <br/>
        - the Bank owns all rights in and to the Intellectual Property and Know-How featured or referred to on the Portal unless we say otherwise;
        <br/>
        <br/>
        - the Bank owns all rights in and to the Portal Ideas and you assign to the Bank all rights in and to the Portal Ideas and the Bank accepts the transfer of these rights from you. The Bank may use the Portal Ideas any way it wants to without needing your permission first;
        <br/>
        <br/>
        - to the extent that any Intellectual Property in the Portal Ideas constitutes a work of copyright the author/creator of the work has waived their moral rights in favour of the Bank;
        <br/>
        <br/>
        - you will help us to give effect to the transfer of any Intellectual Property in the Portal Ideas to us by, for example, signing certain documents. If we ask you in writing to sign and return any documents connected with this Intellectual Property clause and you don’t give these signed documents to us (or someone representing us, for example our lawyer) within 7 (seven) days of being asked then you agree that we will choose a director of the Bank to act as your agent and sign any documents for you now and in the future;
        <br/>
        <br/>
        - we may apply to register in our name and in any country Intellectual Property which you assign to us on the Portal;
        <br/>
        <br/>
        - at the written request of the Bank you will help us to prove that any Intellectual Property that you assign to us on the Portal exists so that it can be used, enforced, and protected in any country;
        <br/>
        <br/>
        - you will not be paid in any way for the Portal Ideas, transferring the rights therein to us, or helping us to prove that any Intellectual Property therein exists; and
        <br/>
        <br/>
        - you will not disclose any Portal Ideas to any third party.
        <br/>
        <br/>
        You accept that you will not:
        <br/>
        <br/>
        - claim to have any rights to the Bank’s Intellectual Property;
        <br/>
        <br/>
        - use or apply to register our Intellectual Property or any other Intellectual Property which is the same as, similar to, and/or confusingly similar to our Intellectual Property in any country, including any business names, metatags, or such similar identifiers;
        <br/>
        <br/>
        - challenge our rights to our Intellectual Property in any country;
        <br/>
        <br/>
        - set up any links to the Portal, for example a hyperlink, frame, metatag, or similar reference; and
        <br/>
        <br/>
        - do anything which could impair our Intellectual Property or the reputation and goodwill associated therewith or us or which could invalidate the registration of our Intellectual Property.
        <br/>
        <br/>
        You warrant that the Portal Ideas are your own, do not belong to any third party, and do not violate another&apos;s rights including but not limited to privacy, confidentiality, or Intellectual Property rights.
        <br/>
        <br/>
        You give up the right to object to (disagree with and challenge) any distortion, mutilation, and other modification to the Intellectual Property that you send to us on the Portal including a work of copyright in all countries.
        <br/>
        <br/>
        You indemnify the Bank against all actions, claims (actual and alleged), lawsuits, costs, demands, expenses, and other liabilities suffered or incurred by the Bank resulting from any third-party claims against us that are connected to:
        <br/>
        <br/>
        - you using our Intellectual Property without permission; or
        <br/>
        <br/>
        - relating to our use and/or ownership of the Portal Ideas.
        <br/>
        <br/>
        If you go against any of these Portal Terms we are allowed to take legal action against you without giving you any warning. You also agree to pay us the costs of this legal action on an attorney-and-own-client scale.
      </DialogContentText>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </div>
  );
}

export default TermsAndConditions;
