/* eslint-disable react/function-component-definition */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../redux/reducers/rootReducer';

interface Props {
  component: React.ElementType;
}
const AuthTeamRoutes: FC<Props> = ({ component: Component }) => {
  const { user } = useSelector((store: RootState) => store.user);

  const isAuthenticated = Boolean(user);

  if (
    isAuthenticated &&
    (user?.userType === 'Team Lead' || user?.userType === 'Team Member')
  ) {
    return <Component />;
  }

  return <Navigate to="/logout" />;
};

export default AuthTeamRoutes;
