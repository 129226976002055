import React from 'react';
import './App.css';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppBar from './Components/AppBar';
import Header from './Components/Header';
import Section from './Components/Section';
// import Eligibilitysection from './Components/Eligibilitysection';
import Cards from './Components/cards';
// import InnoationStages from './Components/InnovationStages';
import Footer from './Components/Footer';
import { RootState } from '../../redux/reducers/rootReducer';

function Homepage() {
  const { user } = useSelector((store: RootState) => store.user);

  const isAuthenticated = user;
  // console.log('isAuthenticated', isAuthenticated);
  if (isAuthenticated && user?.userType === 'Admin') {
    return <Navigate to="/app/dashboard" />;
  }

  if (isAuthenticated && user != null && user.userType === 'Team Lead') {
    return <Navigate to="/team/dashboard" />;
  }

  if (isAuthenticated && user != null && user.userType === 'Team Member') {
    return <Navigate to="/team/dashboard" />;
  }

  if (isAuthenticated && user != null && user.userType === 'Judge') {
    return <Navigate to="/judge/dashboard" />;
  }

  return (
    <div>
      <AppBar />
      <Header />
      <Section />
      {/* <Eligibilitysection /> */}
      <Cards />
      {/* <InnoationStages /> */}
      <Footer />
    </div>
  );
}

export default Homepage;
