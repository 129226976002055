/* eslint-disable import/prefer-default-export */
import React from 'react';
// import { Navigate } from 'react-router-dom';
import TeamLayout from '../../layouts/TeamLayout';
import NotFoundView from '../errors/NotFoundView';
import AccountView from '../TeamView/account';
import Dashboard from './dashboard';
import InnovationProposal from './proposal';
import ProposalForm from './proposal/components/ProposalForm';
import ProposalView from './proposal/components/ProposalView';
import ProposalEditView from './proposal/components/ProprosalEditView';
import TeamsListView from './teams';
import TeamView from './teams/components/TeamView';
import TeamEditView from './teams/components/TeamEditView';
import TeamProposalView from './teams/components/TeamProposalView';
import AuthTeamRoutes from '../../components/AuthTeamRoutes';

export const TeamRoutes = [
  {
    path: 'team',
    element: <TeamLayout />,
    children: [
      { path: 'dashboard', element: <AuthTeamRoutes component={Dashboard} /> },
      {
        path: 'account',
        element: <AuthTeamRoutes component={AccountView} />
      },
      {
        path: 'innovation-idea',

        element: <AuthTeamRoutes component={InnovationProposal} />
      },
      {
        path: 'innovation-create',
        element: <AuthTeamRoutes component={ProposalForm} />
      },
      {
        path: 'innovation-edit',
        element: <AuthTeamRoutes component={ProposalEditView} />
      },
      {
        path: 'innovation-view',
        element: <AuthTeamRoutes component={ProposalView} />
      },
      {
        path: 'teams',
        element: <AuthTeamRoutes component={TeamsListView} />
      },
      {
        path: 'teams/:id',
        element: <AuthTeamRoutes component={TeamView} />
      },
      {
        path: 'teams-edit/:id',
        element: <AuthTeamRoutes component={TeamEditView} />
      },
      {
        path: 'team-innovation-view',
        element: <AuthTeamRoutes component={TeamProposalView} />
      },
      // add routes for editing here
      { path: '404', element: <AuthTeamRoutes component={NotFoundView} /> },
      {
        path: '*',
        element: <AuthTeamRoutes component={NotFoundView} />
      }
    ]
  }
];
