import axios from 'axios';
import Cookies from 'universal-cookie';

// let baseURL = 'https://bluelocker.stanbic.co.zm/api/v1';
// let baseURL = 'http://localhost:3000/api/v1';
let baseURL: string = '';

if (process.env.REACT_APP_API_PRODUCTION) {
  baseURL = process.env.REACT_APP_API_PRODUCTION;
} else if (process.env.REACT_APP_API_STAGING) {
  baseURL = process.env.REACT_APP_API_STAGING;
} else if (process.env.REACT_APP_API_DEV) {
  baseURL = process.env.REACT_APP_API_DEV;
}

const cookies = new Cookies();

const cookieValue = cookies.get('Stanbic');

// console.log('cookieValue', cookieValue);

export const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookieValue ? cookieValue.microsoftUserID : ''}`
  }
});

export default instance;
