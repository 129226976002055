const branchesArray: string[] = [
  'Arcades Branch',
  'Chingola Branch',
  'Chipata Branch',
  'Choma Branch',
  'Cosmopolitan Mall Branch',
  'East Park Mall Branch',
  'Industrial Branch',
  'Kabwata Branch',
  'Kabulonga Branch',
  'Kabwe Branch',
  'Kafubu Mall',
  'Kafue Branch',
  'Kitwe Main',
  'Livingstone Branch',
  'Lusaka Main',
  'Lumwana Branch',
  'Manda Hill Branch',
  'Matero Branch',
  'Mazabuka Branch',
  'Mufulira Branch',
  'Mkushi Branch',
  'Mukuba Mall Branch',
  'Ndola Main',
  'Private Banking Suite - Head Office',
  'Private Banking Suite - Quorum',
  'Solwezi Branch',
  'Woodlands Branch',
  'Waterfalls Branch',
  'Lusaka Main Branch'
];

export default branchesArray;
